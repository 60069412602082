.App {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: 'Raleway', sans-serif;
  text-align: center;
}

.App-nav {
  align-items: baseline;
  display: flex;
  margin-bottom: 20px;
}

.App-nav__home {
  font-size: 40px;
  font-weight: 300;
  margin-top: 50px;
  text-transform: uppercase;
}

.App-nav__item {
  padding: 0 10px 5px 10px;
  text-transform: uppercase;
  cursor: pointer;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  a {
    text-decoration: none;
  }
}

.App-body {
  display: flex;
  justify-content: center;
  align-items: center;
}

.section {
  width: 80%;
}

.container {
  display: flex;
}

.content {
  margin: 0px 20px;
}

.header__headshot {
  max-height: 500px;
  max-width: 100%;
}

.social {
  display: flex;
}

.social-icon {
  margin: 10px 5px 5px 5px;
}

.social-icon-img {
  height: 25px;
}

.navigation {
  align-items: center;
  border-bottom: 1px solid black;
  display: flex;
  margin-bottom: 25px;
  margin-top: 20px;
  justify-content: center;
}

.navigation__link {
  margin: 0 20px 5px 20px;
  text-transform: uppercase;

  &:hover {
    color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
  }
}

.section-header {
  text-transform: uppercase;
}

.about__bio {
  font-size: 14px;
  text-align: left;
}

.resp-container {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
}

.resp-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

@media (max-width: 780px) {
  .App-body {
    width: 90%;
  }
}
