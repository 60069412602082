body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: inherit;
}

#root {
  background-color: #ffffff;
  color: #000000;
  min-height: 100vh;
}

footer {
  display: flex;
  font-family: 'Raleway', sans-serif;
  font-size: 10px;
  justify-content: flex-end;
}

.external-link:hover {
  color: rgba(0, 0, 0, 0.6);
}

.news__item {
  padding: 20px 0;
}

.news__items .news__item + .news__item {
  border-top: solid 1px black;
}

.news__item__date {
  font-weight: 600;
  margin-bottom: 20px;
}

.news__item__instagram-embed {
  display: flex;
  flex-direction: column;
  align-items: center;
}
